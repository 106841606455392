.navbar-container--cc-menu {
  position: relative;
  height: 60px;
  background-size: contain;
  background-position: 50%;
  color: #fff;
  /* padding-right: 18px;
    padding-left: 18px; */
  padding: 0 18px;
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px #07c160;
  /* margin-bottom: 4px; */
  z-index: 999;
}

.navbar-container--cc-menu .left-box {
  /* width: 50%; */
  display: flex;
  justify-content: space-between;
}

.navbar-container--cc-menu .left-menu {
  display: flex;
  align-items: center;
  height: 60px;
  line-height: 60px;
  /* width: 240px; */
  margin-left: 0px;
  margin-right: 48px;
}

.navbar-container--cc-menu .right-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navbar-container--cc-menu .logout {
  display: inline-block;
  font-size: 16px;
  line-height: 54px;
  height: 54px;
  cursor: pointer;
}
.navbar-container--cc-menu .logo {
  display: inline-block;
  flex-shrink: 0;
}

.navbar-container--cc-menu .user {
  font-size: 14px;
  color: #fff;
}

.custom-menu--cc-menu .fixed-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.custom-menu--cc-menu .shadow {
  height: 60px;
}

.navbar-container--cc-menu .navbar-menu {
  height: 53px;
  display: flex;
  align-items: center;
  /* padding-left: 50px; */
  border: 0;
  font-size: 16px;
}

.navbar-container--cc-menu .navbar-menu li {
  height: 60px;
  line-height: 64px;
}
.custom-menu--cc-menu .ant-menu-item {
  margin-right: 28px !important;
  /* margin-bottom: 3px !important; */
  padding: 0 10px !important;
  /* margin: 0 !important; */
}

.custom-menu--cc-menu .ant-menu {
  background: transparent !important;
  color: #fff !important;
}

.custom-menu--cc-menu .ant-menu-item-selected {
  /* border-bottom: 2px solid #fff !important; */
  color: #fff !important;
  font-weight: 600;
}

.custom-menu--cc-menu .ant-menu-item-active {
  border-bottom: none !important;
  color: #fff !important;
  font-weight: 600;
}

.custom-menu--cc-menu .ant-menu .ant-menu-submenu-selected {
  /* border-bottom: 2px solid #fff !important; */
  color: #fff !important;
  font-weight: 600;
  margin-right: 28px !important;
}

.custom-menu--cc-menu .ant-menu-submenu-active {
  /* border-bottom: 2px solid #fff !important; */
  color: #fff !important;
}

.custom-menu--cc-menu .ant-menu-submenu {
  margin-right: 20px !important;
  padding: 0 10px !important;
}

.custom-menu--cc-menu .ant-menu-submenu-title {
  padding: 0px !important;
}

.custom-menu--cc-menu .ant-menu-submenu-title:hover {
  color: #fff !important;
}
.custom-sub-menu--cc-menu .ant-menu-item-active {
  /* background-color: #bd192d !important; */
  color: #fff !important;
  border-bottom: none !important;
  /* font-weight: 600; */
}

.custom-sub-menu--cc-menu .ant-menu-item {
  transition: none !important;
  margin-right: 0 !important;
}

.custom-sub-menu--cc-menu .ant-menu {
  background-color: #fff !important;
  color: #000 !important;
}

.custom-sub-menu--cc-menu .ant-menu-item-selected {
  background-color: #07c160 !important;
  color: #fff !important;
  border-bottom: none !important;
  font-weight: 600;
}

.dropdown--jcb-menu .ant-dropdown-menu-item {
  /* line-height: 32px; */
  width: 100%;
  text-align: center;
  padding: 10px 0;
}
.dropdown--jcb-menu .ant-dropdown-menu-item:hover {
  background: #fff;
}
.dropdown--jcb-menu .logout-area {
  width: 100%;
  /* height: 60%; */
}
.dropdown--jcb-menu .logout-area:hover {
  background: rgba(7, 193, 96, 0.12);
}

.display-none {
  display: none;
}

@media screen and (max-width: 1400px) {
  .custom-menu--cc-menu .ant-menu-item {
    margin-right: 30px !important;
    padding: 0 6px !important;
  }
  .custom-menu--cc-menu .ant-menu-submenu {
    margin-right: 6px !important;
    padding: 0 6px !important;
  }

  .navbar-container--cc-menu {
    padding-right: 12px;
  }

  .navbar-container--cc-menu .navbar-menu {
    padding-left: 10px;
  }

  .navbar-container--cc-menu .left-menu {
    margin-right: 8px !important;
  }
  .navbar-container--cc-menu .ant-menu-horizontal .ant-menu-item {
    padding: 0 6px !important;
  }
}

@media screen and (max-width: 1000px) {
  .custom-menu--cc-menu .ant-menu-item {
    margin-right: 28px !important;
    padding: 0 4px !important;
  }
  .custom-menu--cc-menu .ant-menu-submenu {
    margin-right: 4px !important;
    padding: 0 4px !important;
  }
  .navbar-container--cc-menu {
    padding-right: 12px;
  }
  .navbar-container--cc-menu .navbar-menu {
    padding-left: 0px;
  }
  .navbar-container--cc-menu .left-menu {
    width: 300px;
    flex-basis: 300px;
    margin-right: 0px !important;
  }
  .navbar-container--cc-menu .ant-menu-horizontal .ant-menu-item {
    padding: 0 4px !important;
  }
}
.ant-menu-inline .ant-menu-item::after {
  border-right: 0px solid #07c160 !important;
}
.ant-menu-submenu .ant-menu-submenu-horizontal {
  margin-left: 0 !important;
}
.ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  margin: 0 !important;
}
.photo-box {
  width: 35px;
  height: 35px;
  background: #fff;
  color: #07c160;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 8px;
}
.cc-menu {
  height: 60px;
  border: 0;
  font-size: 16px;
  width: 800px;
  /* width: 600px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #fff;
}
.cc-menu-item {
  line-height: 60px;
  margin-right: 28px !important;
  /* margin-bottom: 3px !important; */
  padding: 0 10px !important;
  cursor: pointer;
  color: #fff;
  opacity: 0.85;
}
.cc-menu-item-active {
  font-weight: 600;
  line-height: 60px;
  margin-right: 28px !important;
  /* margin-bottom: 3px !important; */
  padding: 0 10px !important;
  cursor: pointer;
}
.cc-menu-item-active:hover {
  font-weight: 600;
}
.cc-menu-item:hover {
  font-weight: 600;
  color: #fff;
  opacity: 1;
}
.custom-menu--cc-menu .ant-menu-submenu-selected {
  border-bottom: none !important;
  margin-right: 28px !important;
}
.custom-menu--cc-menu .ant-menu-item .ant-menu-item-only-child:hover {
  font-weight: 600;
}
.custom-menu--cc-menu .ant-menu-item .ant-menu-item-only-child {
  border-bottom: none !important;
}
